"use strict";

var app = {
  mobileMenu: function mobileMenu() {
    var mobileBtn = $("#mobile-burger-btn");
    var pageBody = $("#page-wrap-inner");

    if (mobileBtn.length > 0) {
      // Load Menu
      mobileBtn.click(function () {
        if (mobileBtn.hasClass("menu-open") == true) {
          pageBody.removeClass("push");
          mobileBtn.removeClass("menu-open");
          mobileBtn.removeClass("is-active");
        } else {
          pageBody.addClass("push");
          mobileBtn.addClass("menu-open");
          mobileBtn.addClass("is-active");
        }
      });

      // Sub Item Accordian
      $('#mobile-nav-items li a.parent').click(function (e) {
        e.preventDefault();
        var selected = $(this).parent();
        selected = $(selected[0]);
        var subMenu = selected.find('ul.subitems');
        if (subMenu.hasClass('open') == true) {
          subMenu.removeClass('open');
        } else {
          if ($('#mobile-nav-items ul.subitems').hasClass('open') == true) {
            $('#mobile-nav-items ul.subitems').removeClass('open');
          }
          subMenu.addClass("open");
        }
      });
    }
  },

  validateEmail: function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  init: function init() {
    app.mobileMenu();

    $("#js-testimonial-carousel").slick({
      appendDots: "#testimonialpaging",
      dots: true,
      infinite: true,
      autoplay: false,
      centerMode: true,
      autoplaySpeed: 5000,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: "#nxt-btn2",
      prevArrow: "#prev-btn2",
      cssEase: "linear"
    });

    $("#alu-slideshow").slick({
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      cssEase: "linear"
    });

    $("#js-door-carousel").slick({
      appendDots: "#doorpaging",
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 6,
      slidesToScroll: 1,
      nextArrow: "#nxt-btn3",
      prevArrow: "#prev-btn3",
      cssEase: "linear",
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }, {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }]
    });

    $("#js-gallery-carousel").slick({
      appendDots: "#gallerypaging",
      dots: false,
      infinite: true,
      speed: 300,
      centerMode: true,
      autoplay: false,
      autoplaySpeed: 3000,
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: "#nxt-btn4",
      prevArrow: "#prev-btn4",
      cssEase: "linear",
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }, {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }]
    });

    $("#js-featured-carousel").slick({
      appendDots: "#featuredpaging",
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: "#nxt-btn5",
      prevArrow: "#prev-btn5",
      cssEase: "linear",
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }, {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    });

    $("#js-general-carousel").slick({
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      centerMode: true,
      speed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      cssEase: "linear"
    });

    $("#instagram-feed").slick({
      appendDots: "#instagrampaging",
      dots: true,
      infinite: true,
      speed: 300,
      autoplay: false,
      centerMode: true,
      autoplaySpeed: 3000,
      slidesToShow: 5,
      slidesToScroll: 2,
      nextArrow: "#nxt-btn",
      prevArrow: "#prev-btn",
      cssEase: "linear",
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }, {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }]
    });

    // INSTRAGRMA FEED
    if ($("#instagram-feed li").length > 0) {
      $("#instagram-feed li a").click(function (e) {
        e.preventDefault();
        var item = $(this);
        var embed = item.attr("data-embed");
        $.fancybox.open({
          type: "html",
          src: '<div class="fc-content rounded frm-dialog">' + '<div class="dialog-item-inner"><iframe frameborder="0", height="710", scrolling="no", width="612", allowtransparency="true", src="' + embed + '"></div>' + "</div>"
        });
      });
    }
  }
};

$(document).ready(function () {
  app.init();

  var $grid = $(".gallerylayout").masonry({
    itemSelector: ".gallery-item",
    percentPosition: true
  });
  $grid.imagesLoaded().progress(function () {
    $grid.masonry("layout");
  });
});